<template>
  <div>
    <div class="header text-center mt-5">
      <img src="@/assets/images/logo/flipmoney.png" />
    </div>
    <vs-card class="container">
      <div class="mt-5">
        <h4 style="font-size: medium;">
          <strong>
            PAYMENTS GENERAL TERMS& CONDITIONS
          </strong>
        </h4>
        <p class="mt-5">
          Flip  Money  Pty  Ltd  ACN  640  607  932  (“we”, “our”, “us”,  “Flip”  or  “FlipMoney”)  is a B2Bpayments
          technology company servicing Australian businesses. Flip has partnered by with Till Payments Solutions Pty Ltd
          ABN 64 160 726 349 (“Till Payments”) to provide Merchant Services which includes but not limited to: the
          acquiring, switching and processing of Card based transactions and transactions using other payment instruments
          via electronic funds transfer at point of sale(EFTPOS) or online.
          <br><br>
          The following Terms and Conditions set out and govern the Customer’s use of and access to Flip’s Services form
          part of the Merchant Services Agreement  that  includes  these Terms,  the  Privacy  Policy  and  the  terms
          contained  within  the  approved Merchant  Application  Form (the “Agreement”).Please read the Terms below carefully.
        </p>
      </div>
      <div class="mt-5">
        <ol>
          <li>
            <b>DEFINITIONS</b>
            <p>
              In addition to the terms defined throughout these General Terms,
              the following terms mean as follows:
            </p>
            <ol class="list-none">
              <li>
                <strong>Acquiror:</strong> Till Payments Solutions Pty Ltd ABN 64 160 726 349 (“Till
                Payments”), the acquiring service provider.
              </li>
              <li>
                <strong>Agreement</strong>. means this Agreement, including the Merchant
                Application Form and its Schedules, and the
                <a href="https://flipmoney.com.au/privacy-policy/">
                  Flip Privacy Policy</a>.
                as published on our Website.
              </li>
              <li>
                <strong>Application Form</strong> means Customer’s online Flip Payments
                application form submitted to Flip and approved by Till Payments as
                the Acquiror.
              </li>
              <li>
                <strong>Authorisation:</strong> The confirmation by the card Issuer that the card
                number exists and that enough funds are available to allow the
                Transaction to go ahead.
              </li>
              <li>
                <strong>Business Day:</strong> A day other than Saturday or Sunday or public
                holiday in New South Wales.
              </li>
              <li>
                <strong>Card:</strong> A payment card or any form factor that can be used to initiate
                a payment transaction as specified on the Application.
              </li>
              <li>
                <strong>Cardholder:</strong> The individual whose name is embossed on a Card
                and any authorised user of such Card.
              </li>
              <li>
                <strong>Card Scheme:</strong> Any entity formed to administer and promote Cards,
                including without limitation MasterCard International Inc, Visa
                International Inc, eftpos Australia Limited.
              </li>
              <li>
                <strong>Card Scheme Rules:</strong> The rules, regulations, releases,
                interpretations and other requirements (whether contractual or
                otherwise) imposed or adopted by any Card Scheme.
              </li>
              <li>
                <strong>Commencement Date</strong> means the commencement date recorded
                in Schedule B of the Merchant Application Form.
              </li>
              <li>
                <strong>Confidential Information:</strong> means, in relation to either party, any
                information:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    relating to the terms of this Agreement;
                  </li>
                  <li>
                    relating directly or indirectly to research or development by,
                    accounting for, or the marketing of, the business of that party or
                    its suppliers or customers;
                  </li>
                  <li>
                    disclosed by that party to the other party on the express basis
                    that such information is confidential; or
                  </li>
                  <li>
                    which might reasonably be expected by the other party to be
                    confidential in nature;
                  </li>
                </ol>
              </li>
              <li>
                <strong>Control</strong> has the meaning given to it in Section 50AA of the
                Corporations Act 2001 (Cth);
              </li>
              <li>
                <strong>Customer</strong> means the party making a Request for Services and
                entering into this Agreement

                Dashboard means the online portal that Flip provides to the
                Customer as part of the Services;
              </li>
              <li>
                <strong>Documentation</strong> means any user, training or system manuals for Flip
                (whether in printed or electronic form) which describes and provides
                guidance on the use of Flip.
              </li>
              <li>
                <strong>Employees:</strong> Employees, contractors, officers, agents and
                secondees.
              </li>
              <li>
                <strong>Emergency Support</strong> means telephone support provided by Till
                Payments for emergency breakdowns resulting in repeated failures
                in the transmission of Transactions and is available 24 hours a day,
                seven days a week by calling 1300 369 692 outside of Support
                Hours.
              </li>
              <li>
                <strong>Fees</strong> means transaction fees and any other fees, costs or expenses
                payable by the Customer to pay Flip as detailed in Schedule B of
                the Merchant Application Form;
              </li>
              <li>
                <strong>General Terms:</strong> The terms and conditions set out in this document,
                as amended from time to time.
              </li>
              <li>
                <strong>Goods</strong> means the POS Terminal or devices issued to the Customer
                to process Card payments by the Acquiror.
              </li>
              <li>
                <strong>GST</strong> means GST as defined in the A New Tax System (Goods and
                Services Tax) Act 1999 (Cth), or any like tax.
              </li>
              <li>
                <strong>Insolvency Event:</strong> Any of the following:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    an order is made or an application is made to a court for an
                    order that a party be wound up;
                  </li>
                  <li>
                    an application is made to a court for an order appointing a
                    liquidator or provisional liquidator or a liquidator or provisional
                    liquidator is appointed in respect of a party;
                  </li>
                  <li>
                    a party enters into, or resolves to enter into, a scheme of
                    arrangement, deed of company arrangement or composition
                  </li>
                  <li>
                    with, or assignment for the benefit of, all or any classes of its
                    creditors, or it proposes a re-organisation, moratorium or
                  </li>
                  <li>
                    other administration involving any of them;
                  </li>
                  <li>
                    a party resolves to wind itself up, or otherwise dissolve itself, or
                    gives notice of intention to do so, except to reconstruct or
                    amalgamate while solvent on terms approved by the other party
                    or is otherwise wound up or dissolved;
                  </li>
                  <li>
                    a party is or states that it is unable to pay its debts when they
                    fall due;
                  </li>
                  <li>
                    a party takes any step to obtain protection or is granted
                    protection from its creditors, under any applicable legislation or
                    an administrator is appointed to a party; or
                  </li>
                  <li>
                    anything analogous or having a substantially similar direct effect
                    to any of the events specified in paragraphs (a)-(f) above
                    happens under the law of any applicable jurisdiction.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Intellectual Property</strong> means registered and unregistered
                trademarks (including logos and trade files), domain names,
                copyright, patents, petty patents, utility models, registered and
                unregistered designs, circuit layouts, rights in computer software,
                databases and lists, Confidential Information, software (whether in
                object code or source code), and all other rights anywhere in the
                world resulting from intellectual activity, whether registerable or not;
              </li>
              <li>
                <strong>Merchant:</strong> The party identified as the “Customer” on the Merchant
                Application Form. The words “you” and “your” refer to the Merchant
                or Customer.
              </li>
              <li>
                <strong>Merchant Services:</strong> the provision of sales, service and support to
                Merchants, including but not limited to: the acquiring, switching and
                processing of Card based transactions and transactions using other
                payment instruments via electronic funds transfer at point of sale
                (EFTPOS), or through the internet.
              </li>
              <li>
                <strong>PCI Standards</strong> means the Payment Card Industry standards,
                requirements and guidelines issued by the Payment Card Industry
                Data Security Council from time to time including the Payment Card
                Industry Data Security Standard PIN Entry Device requirements and
                guidelines, and the Payment Application Data Security Standard;
              </li>
              <li>
                <strong>Permitted Use</strong> means the transmission to, and receipt from, an
                Acquirer of data relating to Supported Transactions and expressly
                excludes, without limitation, use for the processing of transactions
                of, or for the benefit of, any person other than Customer;
              </li>
              <li>
                <strong>Personal Information</strong> means personal information as defined in the
                Privacy Act 1988 (Cth).
              </li>

              <li>
                <strong>PPSA</strong> means the Personal Property Securities Act 2009 (Cth).
                Related Company is a related entity that is part of the same
                ownership structure (either vertically or horizontally) as Customer,
                including a company incorporated outside Australia;
              </li>

              <li>
                <strong>POS Terminal:</strong> A point of sale device placed in a merchant location
                which is connected to the Till Payments system via telephone lines
                and is designed to authorise, record and settle data by electronic
                means for all sales Transactions with Till Payments.
              </li>

              <li>
                <strong>Privacy Law:</strong> The Privacy Act 1998 (Cth), any common law privacy
                obligations by which a party is bound and any industry code
                (whether voluntary or not) by which a party is bound.
              </li>

              <li>
                <strong>Request for Services</strong> means a request submitted by the Customer
                to Flip to use or have access to the Services;
              </li>

              <li>
                <strong>Security Interest</strong> means a a right, interest, power or arrangement in
                relation to any property which provides security for, or protects
                against default by a person in, the payment or satisfaction of a debt,
                obligation or liability, including a mortgage, charge, bill of sale,
                pledge, deposit, lien, encumbrance or hypothecation and a security
                interest as defined in section 12(1) and (2) of the PPSA.
              </li>

              <li>
                <strong>Security Standards</strong> means each of: any data protection or data
                security standards issued by an Acquirer which receives and
                accepts Supported Transactions from Customer; and the PCI
                Standards;
              </li>

              <li>
                <strong>Services</strong> means the services to be supplied to the Customer by Flip
                pursuant to the Customer’s Request for Services and may include
                the POS Terminal, online payments and payment gateway solutions.
              </li>

              <li>
                <strong>Software:</strong> All software, computer programs, related documentation,
                technology and processes embodied in or provided in connection
                with the Services.
              </li>

              <li>
                <strong>Support Hours</strong> means the period from 9am to 5pm (AEST) on any
                Business Day.
              </li>

              <li>
                <strong>Support Services</strong> means and includes:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Online Help: from time to time we may display Frequently Asked
                    Questions and Answers on the Flip Website;
                  </li>
                  <li>
                    General Support: enquiries may be sent to:
                    support@flipmoney.com.au or made by telephone to 1300 354
                    766. We will respond to such enquiries during Support Hours;
                  </li>
                </ol>
              </li>
              <li>
                <strong>Till Payments</strong> means Till Payments Solutions Pty Limited (ABN: 64
                160 726 349), a company incorporated in Australia and the Acquiror
                with whom Flip has signed an exclusive partnership agreement with
                for the provision of Services.;
              </li>
              <li>
                <strong>Transaction:</strong> Includes a sales Transaction (being the supply of
                goods or services or both), a cash out Transaction, Refund, or Cash
                Related Transaction in which a Card or Card number is used and
                which is processed by the Merchant either manually or
                electronically
              </li>
              <li>
                <strong>Website</strong> means  <a href="https://www.flipmoney.com.au">www.flipmoney.com.au</a>
              </li>
            </ol>
          </li>
          <li>
            <b>General</b>
            <ol>
              <li>
                Parties This Agreement is between Flip and the Customer.
              </li>
              <li>
                Initial Term: This Agreement commences on the
                Commencement Date and will continue for the Initial Term.
                The Customer acknowledges that by its ongoing use of and
                access to the Services, it is deemed to accept these Terms
                and the terms of the Agreement
              </li>
              <li>
                Renewal: Upon expiry of the Initial Term, this Agreement will
                automatically continue for further periods of the number of
                consecutive months as documented in the Merchant
                Application Form on the same terms and conditions unless
                either party gives the other party not less than 3 months'
                notice in writing prior to the expiry of the Initial Term or the
                then current Renewal Term that it does not wish this
                Agreement to continue beyond the Initial Term or that
                Renewal Term as the case may be.
              </li>
              <li>
                Amendment to Terms
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Flip may amend these Terms or any other term of this
                    Agreement at any time on 30 days’ notice by posting the
                    amended terms on its website and by emailing the
                    Customer at the Customer’s email address on file. The
                    terms and conditions that will apply to any Services are
                    the most recent terms posted on Flip’s Website.
                  </li>
                  <li>
                    The Customer may, within 90 days of updated Terms
                    being posted and emailed, give Flip notice that it wishes
                    to terminate this Agreement on 30 days notice.
                  </li>
                  <li>
                    The Customer acknowledges and agrees that the
                    Customer’s continued use of the Services after that date
                    any amended Terms are posted on Flip’s website will be
                    deemed acceptance of the amended terms and/or
                    increased fees for Services or modification of the terms,
                    throughout the remaining term of this Agreement or use
                    of the Services whichever the longer.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Payment</b>
            <ol>
              <li>
                Fees: Customer will pay Flip the Fees without setoff or
                deduction monthly in arrears on the 10th day of the month
                following the date of the invoice for those Fees.
              </li>
              <li>
                Taxes: Customer is responsible for all GST and any other
                taxes, duties and levies (other than Flip’s income tax)
                assessed in connection with its use of Flip and the provision
                of services under this Agreement.
              </li>
              <li>
                Default Interest for Late Payment: Subject to clause 3.4(b) the
                Customer must pay the Default Interest Rate on any overdue
                amounts under this Agreement, calculated daily from the due
                date until the actual date of payment. The Customer will pay
                all reasonable costs (including collection costs and legal
                costs on a solicitor-client basis) that we incur in attempting to
                recover or recovering any such overdue amounts.
              </li>
              <li>
                Disputed invoices: Where Customer has reasonable grounds
                to dispute any portion of any amount invoiced under this
                Agreement (disputed portion):
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Customer will notify Flip of such dispute and the grounds
                    for such dispute within 5 Business Days of receiving the
                    invoice;
                  </li>
                  <li>
                    any undisputed portion of the invoice will remain payable
                    on the due date for payment;
                  </li>
                  <li>
                    provided that Customer has complied with clause 3.4(a),
                    Customer will not be required to pay:
                    <ol style="list-style-type: lower-roman;">
                      <li>
                        the disputed portion until the parties' dispute has
                        been resolved by agreement between the parties or
                      </li>
                      <li>
                        any interest under, clause 3.3 on the disputed portion
                        unless the dispute is resolved with the effect that
                        Customer is required to pay all (or part) of the
                        disputed portion, in which case Customer will pay the
                        interest on the disputed portion (or that part of that
                        disputed portion) in accordance with clause 3.3.
                        Interest will be payable from the date that payment
                        would have been due under clause 3.1 but for the
                        dispute until the date that disputed portion is paid in
                        full.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>
              Joint Responsibilities
            </b>
            <p>
              Each party represents and agrees:
            </p>
            <ol>
              <li>
                Power and Authority: that it has full power and authority
                necessary to validly enter into and perform all its obligations
                under this Agreement; and
              </li>
              <li>
                Requirements: to comply with the other party's reasonable
                security, confidentiality and operational requirements of
                which it has been given reasonable notice.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Customer Responsibilities
            </b>
            <p>
              The Customer agrees that it:
            </p>
            <ol>
              <li>
                Suitability: has made, and will continue to make, its own
                assessment of the suitability, adequacy, compatibility and
                appropriateness of Services for its purposes;
              </li>
              <li>
                Comply with Instructions: will comply with Flip’s restrictions,
                instructions and Documentation in relation to the use of Flip
                Payments, including those set out in this Agreement;
              </li>
              <li>
                Use by Others: will ensure that only authorised persons use
                Services and is used only for the Permitted Use and as
                expressly authorised under this Agreement;
              </li>
              <li>
                Responsibility for Use: will accept all responsibility for Flip
                reliance on and use of solutions by Customer and its
                employees, contractors and agents;
              </li>
              <li>
                Obtain Equipment: will obtain and maintain all equipment,
                software and services needed to enable it to receive and use
                the Services;
              </li>
              <li>
                Accurate Customer Information: warrants that all information
                provided by Customer to Flip in the Application Form or other
                communication is accurate and correct, and that Customer
                will notify Flip in writing if such information changes or ceases
                to be accurate in any way;
              </li>
              <li>
                Provide Sufficient Information: will ensure that sufficient
                information is given to Flip to enable Flip to comply with its
                obligations under this Agreement and that such information is
                timely, complete and accurate;
              </li>
              <li>
                Personal Information: acknowledges that any Personal
                Information concerning Customer or its personnel which is
                provided to Flip by or on behalf of Customer may be:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    used by Flip for the purpose of providing Services,
                    Support Services and any other services to
                    Customer; and
                  </li>
                  <li>
                    disclosed by Flip to its Related Companies and other
                    services providers to enable Flip to provide Services,
                    the Support Services and any other services to
                    Customer. A copy of the Personal Information held by
                    Flip will be provided on written request. Further
                    information is available in our
                    <a href="https://flipmoney.com.au/privacy-policy/">Privacy Policy</a>
                    available online at <a href="https://flipmoney.com.au"> www.flipmoney.com.au. </a>
                  </li>
                </ol>
              </li>
              <li>
                Notify of Third Party Infringement: will immediately notify Flip
                upon becoming aware of any third party infringing Flip’s
                Intellectual Property rights in any manner;
              </li>
              <li>
                Responsible for Connecting to the Flip Portal: is responsible
                for all charges and costs associated with connecting to the
                Flip Portal to operate Flip. Flip will provide Customer with
                reasonable technical information and Software necessary to
                enable Customer to connect to the Flip Portal; and
              </li>
              <li>
                Comply with Law Generally: will comply with all relevant laws,
                including all statutory, regulatory and common laws, in its use
                of Flip and carrying out its obligations under this Agreement
              </li>
            </ol>
          </li>
          <li>
            <b>Our General Responsibilities</b>
            <ol>
              <li>
                Care and skill: Flip will perform its obligations under this
                Agreement with due care, skill and diligence; and
              </li>
              <li>
                Good faith: Flip will: comply with all relevant laws and cooperate with Customer (and Customer's service providers) in
                connection with the Services.
              </li>
              <li>
                Data Security: Flip has implemented data security measures
                to ensure the security and protection of Cardholder Data in
                accordance with the current applicable payment card
                industry data security standard (PCI DSS) requirements
                created by the Payment Card Industry Security Standards
                Council.
              </li>
              <li>
                Privacy: In supplying the Services, Flip will comply with the
                Privacy Act 1988 (Cth) and associated Australian Privacy
                Principles;
              </li>
              <li>
                General Assistance or information: In addition to the Services,
                Flip may, in its absolute discretion, provide general assistance
                or information to the Customer from time to time.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Warranties
            </b>
            <ol>
              <li>
                Security Standards: the Acquiror and Flip will throughout the
                term of this Agreement use its best endeavours to comply
                with the requirements of the Security Standards
              </li>
              <li>
                Limitations on Warranties: To the fullest extent permitted by
                law, except as expressly set out in this Agreement, Flip
                excludes all warranties, conditions, terms, representations or
                undertakings, whether express, implied, statutory or
                otherwise, including any condition or warranty of
                merchantability or fitness for a particular purpose. To the
                fullest extent permitted by law, Flip does not warrant that:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    the Software or the Documentation will meet
                    Customer's requirements; or
                  </li>
                  <li>
                    the Software and the Documentation will be
                    uninterrupted or error free, or that all errors will be
                    corrected.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Intellectual Property
            <ol>
              <li>
                All Intellectual Property rights provided by Flip, the Software,
                the Documentation and any work or thing developed or
                created by or on behalf of Flip under or in connection with
                this Agreement (such work being Developed Works), are
                exclusively owned by Flip. The Customer acknowledges that
                there is no transfer of title, Intellectual Property rights or
                ownership of the Software, the Documentation or any part
                thereof; or any Developed Works; to Customer under this
                Agreement and Customer will not dispute Flip’s ownership of
                the property referred to in this clause.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Indemnity
            </b>
            <ol>
              <li>
                The Customer indemnifies Flip at all times against any liability,
                loss (including consequential loss), damage or cost (including
                legal costs on a solicitor-client basis) suffered or incurred by
                Flip, its employees, contractors and agents, and all actions,
                proceedings, claims or demands made against Flip as a result
                of any negligent act or omission or any breach of this
                Agreement by Customer, its personnel or agents
              </li>
            </ol>
          </li>
          <li>
            <b>
              Liability
            </b>
            <p>
              To the fullest extent permitted by applicable law:
            </p>
            <ol>
              <li>
                Remedy: Subject to clauses 10.2 and 10.3, Customer's sole
                and exclusive remedy for breach of any warranty or of any of
                Flip’s obligations under this Agreement is (at our option) the
                supply or re-supply of Services, the Software, or the
                Documentation or the refund or waiver of Fees for the
                relevant part of Services, the Software, or the Documentation
                which is the subject matter of, or directly related to, the
                breach
              </li>
              <li>
                Limitation: To the fullest extent permitted by law, in no event
                will Flip’s total liability to Customer in connection with this
                Agreement for any one or more defaults as described in
                clause 10.1 exceed the amount paid by Customer to Flip under
                this Agreement in the 3 months following execution of this
                Agreement.
              </li>
              <li>
                Exclusion: To the fullest extent permitted by law, in no event
                will Flip be liable to Customer whether in contract, tort
                (including negligence) or otherwise in respect of any:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    ding negligence) or otherwise in respect of any:
                    (a) loss of data, lost profits, loss of revenue, loss of
                    goodwill, loss of business or for any indirect,
                    consequential or special loss, damage, cost or
                    expense suffered or incurred by Customer arising out
                    of, or in connection with, this Agreement;
                  </li>
                  <li>
                    loss, damage, cost or expense suffered or incurred by
                    Customer, to the extent this results from any act or
                    omission by Customer; or
                  </li>
                  <li>
                    any event described in clause 14.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>
              Competition and Consumer Act
            </b>
            <ol>
              <li>
                This Agreement must be read and construed subject to any
                statutory provisions which cannot lawfully be excluded,
                restricted or modified, including the Australian Consumer
                Law. If any such statutory provisions apply then, to the extent
                to which Flip is entitled to do so and at Flip’s option, Flip limits
                its liability pursuant to such provisions for any goods to the
                replacement or repair of goods, or for services, to the resupply or the payment of the cost of having the services
                supplied again.
              </li>
            </ol>
          </li>
          <li>
            Termination
            <ol>
              <li>
                Termination Upon Notice:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Flip may terminate this Agreement at any time on not
                    less than 3 months' prior written notice to Customer.
                    Customer may terminate this Agreement at any time on
                    not less than 3 months' prior written notice to Flip,
                    provided:
                  </li>
                  <li>
                    During the Initial Term, Customer will be liable for the
                    Fees for the remainder of the Term, which,
                    notwithstanding clause 3.1, shall be payable
                    immediately on giving such notice;
                  </li>
                  <li>
                    During any Renewal Term, Customer will be liable for
                    the Fees for the remainder of the Renewal Term, which,
                    notwithstanding clause 3.1, shall be payable
                    immediately on giving such notice
                  </li>
                </ol>
              </li>
              <li>
                Termination for Cause: Either party (the First Party) may
                terminate this Agreement at any time and with immediate
                effect by written notice to the other party (Second Party) if the
                Second Party:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    is in material breach of any of its obligations under this
                    Agreement, and has failed to remedy the breach within
                    10 Business Days of receiving written notice from the
                    First Party to remedy the breach; or
                  </li>
                  <li>
                    goes into liquidation or has a receiver appointed; or
                  </li>
                  <li>
                    is unable to pay its debts as they fall due; or
                  </li>
                  <li>
                    is insolvent or deemed insolvent under the
                    Corporations Act (2001) (Cth).
                  </li>
                </ol>
              </li>
              <li>
                Breach of Licence Terms: Without limiting clause 12.2(a), any
                use of Services, the Software, or the Documentation by Customer
                for any purpose other than the Permitted Use, or
                any breach by Customer of the provisions of clause 16 will be
                deemed to be a material breach of this Agreement which for
                the purposes of clause 12.2(a) is not capable of remedy.
              </li>
              <li>
                Consequences:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Amounts owing at termination: If this Agreement is
                    terminated by Flip prior to the expiry of the Term,
                    Customer must pay the unpaid balance of the Fees
                    due under this Agreement and the cost of Flip’s
                    reasonable expenses in terminating this Agreement.
                  </li>
                  <li>
                    Expiry or termination of this Agreement will not affect
                    the rights or obligations of the parties which have
                    accrued prior to or accrue on termination or which by
                    their nature are intended to survive termination.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Confidentiality</b>
            <ol>
              <li>
                Confidential Information: Each party will maintain as
                confidential at all times, and will not at any time, directly or
                indirectly, disclose or permit to be disclosed to any person;
                use for itself or to the detriment of the other party; any
                Confidential Information of the other party except as, and then
                only to the extent:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    required by law;
                  </li>
                  <li>
                    that the information is already or becomes public
                    knowledge, otherwise than as a result of a breach, by
                    the receiving party, of any provision of this Agreement;
                  </li>
                  <li>
                    that the information is disclosed to the receiving party,
                    without restriction, by a third party and without any
                    breach of confidentiality by the third party;
                  </li>
                  <li>
                    that the information is developed independently by the
                    receiving party without reliance on any of the
                    Confidential Information of the other party;
                  </li>
                  <li>
                    authorised in writing by the other party; or
                  </li>
                  <li>
                    reasonably required by this Agreement (and, without
                    limiting the effect of this clause, a party may disclose
                    Confidential Information of the other party only to those
                    of its officers, employees or professional advisers on a
                    "need to know" basis, as is reasonably required for the
                    implementation of this Agreement)
                  </li>
                </ol>
              </li>
              <li>
                Flip’s Intellectual Property: Without limiting the effect of clause
                13.1, the Customer will treat information about Flip’s Intellectual
                Property as the Confidential Information of Flip.
              </li>
            </ol>
          </li>
          <li>
            <b>Force Majeure</b>
            <p>
              Neither party (Affected Party) will be liable for any act, omission or
              failure by it under this Agreement if that act, omission or failure
              results directly from an event or circumstances beyond the
              reasonable control of the Affected Party, provided that
            </p>
            <ol>
              <li>
                Notice: whenever the Affected Party becomes aware that
                such a result has occurred or is likely to occur, the Affected
                Party will, as soon as practicable, notify the other party by
                written notice accordingly;
              </li>
              <li>
                Continued Performance: each party will continue to use its
                best endeavours to perform its obligations as required under
                this Agreement; and
              </li>
              <li>
                No Deemed Acceptance of Extra Costs: neither party will be
                deemed to have accepted any liability to pay or share any
                extra costs which may be incurred by the other party in
                complying with this clause or otherwise resulting from such
                act, omission or failure; and
              </li>
              <li>
                Charges: this clause 14 will not apply in respect of Customer's
                obligation to pay any charges or Fees payable under this
                Agreement.
              </li>
            </ol>
          </li>
          <li>
            Licence
            <ol>
              <li>
                Grant of Licence: Flip grants to Customer a non-exclusive,
                non-transferable licence to use the Services, Software and
                the Documentation for the Term of this Agreement solely for
                the Permitted Use. Any other use or dealings by Customer
                without the prior written consent will be a material breach of
                this Agreement. Except to the extent specifically authorised
                under this Agreement, Customer must not sub-license,
                transfer, assign, rent or sell any of the Goods, Software or the
                Documentation or the right to use the Goods, Software or the
                Documentation.
              </li>
              <li>
                Warranty: Flip warrants that it has the right and authority to
                grant to Customer the licence set out at clause 15.1, in
                accordance with the terms of this Agreement
              </li>
            </ol>
          </li>
          <li>
            Terms of Use
            <ol>
              <li>
                Adequacy: Customer must satisfy itself as to the adequacy,
                appropriateness and compatibility of Flip Payments for its
                requirements. Without limiting clause 7.1, Customer
                acknowledges that it has not relied on any statements or
                representations on the part of Flip Payments as to
                performance or functionality, verbal or otherwise, except as
                expressly recorded in this Agreement
              </li>
              <li>
                Logo: The Flip Logo must not be altered or used for any other
                purpose without the prior written consent of Flip. Except as
                provided for in this Agreement, Customer will have none of
                the powers conferred on authorised users of trademarks by
                section 26 of the Trade Marks Act 1995 (Cth)
              </li>
              <li>
                Compliance: If Customer is not compliant with one or more of
                the Security Standards, Customer must not capture or store
                any credit or debit card number or expiry date locally on
                Customer's or a non-compliant third party’s system.
              </li>
              <li>
                No Right to Copy, Alter or Modify: Subject to clause 16.8,
                Customer must not, and must not permit any other person to,
                copy, reproduce, translate, adapt, vary, repair or modify all or
                any part of the Goods, the Software or the Documentation by
                any means or in any form without our prior written consent.
              </li>
              <li>
                Permitted Use: Customer may not:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    use the Software or the Documentation for any
                    purpose other than the Permitted Use; or
                  </li>
                  <li>
                    use the Software independently of the other
                    components of the Services unless Till Payments has
                    given prior written consent to do so. If this Agreement
                    is terminated, Customer's right to use Services will
                    automatically terminate and Customer must
                    immediately remove all copies of the Software from its
                    system(s) and return the Goods, the Software and the
                    Documentation to Flip.
                  </li>
                </ol>
              </li>
              <li>
                Reverse Engineering: Except as expressly permitted by this
                Agreement, and except to the extent that applicable laws
                (including the Copyright Act 1968 (Cth)) prevent Flip
                restraining the Customer from doing so, the Customer must
                not adapt, modify, reverse engineer, decompile or
                disassemble the Software.
              </li>
              <li>
                No Third-Party Use: Except as expressly provided for in this
                Agreement, the Customer must not provide, or otherwise
                make available, the Software or the Documentation or any
                component thereof in any form to any person (Third Party)
                without the prior written consent of Flip Payments. If Flip
                grants such consent, Customer must ensure that the Third
                Party complies with the provisions of clauses 5.2, 5.9, 15.1, 16,
                8 and 13 (so far as those provisions relate to Customer) as if
                the Third Party were a party to this Agreement. Customer will
                be liable to Flip Payments for all acts or omissions of any Third
                Party in contravention of the provisions of clauses 15.1, 16, 8
                and 13.
              </li>
              <li>
                Backup Copies: Customer may make a reasonable number
                of copies of the Software for backup and disaster recovery
                purposes only
              </li>
              <li>
                Installation: Customer will be responsible for, and all bear all
                costs associated with, the installation, operation, maintenance
                and support of the Goods.
              </li>
              <li>
                Labels: Customer will not remove or deface any labels affixed
                to the Goods. Customer will not affix any label to the Goods
              </li>
            </ol>
          </li>
          <li>
            <b>Rental/Loan of Goods</b>
            <ol>
              <li>
                Where the Customer agrees to rent/loan Goods, the following
                terms and conditions apply:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Ownership: Legal and beneficial ownership of, and title
                    to, the Goods, the Software and the Documentation
                    remains at all times with Flip;
                  </li>
                  <li>
                    Risk: The Customer will bear the risk of, and be
                    responsible for, all loss (including theft) of or damage to,
                    © Flip Money Pty Ltd | Page 5 of 6
                    the Goods from the time that they are first delivered until
                    the time that they are returned back to us;
                  </li>
                  <li>
                    Maintain Goods: The Customer must take reasonable
                    steps to avoid exposure of the Goods to heat, dust and
                    moisture, liquids and electrical and physical shock;
                  </li>
                  <li>
                    Licence Terms: The Customer must comply with all
                    manufacturer's licence terms applicable to the Goods (as
                    supplied with the Goods or otherwise notified by us from
                    time to time);
                  </li>
                  <li>
                    Theft, Seizure, Loss, Damage, Repairs: To the maximum
                    extent permitted by law, and subject to clause 11.1, if for
                    any reason the Goods are stolen, seized, lost, damaged
                    or require repairs, the Customer must immediately advise
                    us of the theft seizure, loss, damage or repairs required.
                    Customer must pay all costs and comply with all
                    directions in connection with recovering the Goods,
                    repairing any damage or the replacing of any parts (fair
                    wear and tear excepted). Customer must continue to pay
                    the Fees while the Goods are being repaired or, in the
                    event of theft, seizure or loss, while the Goods are
                    missing. We will not be liable for any loss or damage in
                    relation to the unavailability of the Goods;
                  </li>
                  <li>
                    No Dealing or Modifying or Security Interests: the
                    Customer must not sell, lend, lease, transfer, modify or
                    otherwise deal with the Goods without first obtaining our
                    written consent. Customer must not create, or allow to be
                    created, a Security Interest over the Goods;
                  </li>
                  <li>
                    Inspection: the Customer must allow us to inspect the
                    Goods at any time during normal business hours,
                    provided that we provide not less than 2 Business Days
                    notice;
                  </li>
                  <li>
                    Return of Goods: When this Agreement ends (either by
                    completion of the Term or by way of termination),
                    Customer will, at Customer’s expense, return the Goods,
                    in good working order and undamaged condition (fair
                    wear and tear excepted) to Flip Payments’ place of
                    business. If the Customer fails to return the Goods within
                    14 Business Days of the end of the Agreement, Customer
                    will pay the fair value of the Goods as at the completion
                    of the Term;
                  </li>
                  <li>
                    Repossession: If Customer does not return the Goods in
                    accordance 17.1(h), we may enter any premises where we
                    reasonably believes that the Goods may be located and
                    the Customer will provide all reasonable authority and
                    assistance to enable recovery of those Goods by us. The
                    Customer will be liable for all costs (including legal costs
                    on a solicitor-client basis) incurred by us in recovering the
                    Goods;
                  </li>
                  <li>
                    Further supply: The rental/loan of Goods to the Customer
                    does not guarantee we will supply Goods beyond the
                    Initial Term;
                  </li>
                  <li>
                    PPSA: if we determines that this Agreement contains a
                    Security Interest for the purposes of the PPSA, we may
                    consult with the Customer in relation to what steps (if any)
                    it may take to ensure that the Security Interest is
                    enforceable, perfected and otherwise effective. We do
                    not need to give any notice under the PPSA (including a
                    notice of a verification statement unless the notice is
                    required by the PPSA and cannot be excluded. To the
                    extent permitted by law, the sections of the PPSA listed
                    in section 115(1) of the PPSA will not apply on the
                    enforcement by us of any Security Interest provided for,
                    created or evidenced by this Agreement
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>
              Purchase of Goods
            </b>
            <ol>
              <li>
                Where the Customer agrees to purchase the Goods, the
                following terms and conditions apply:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Title: Title in any of the Goods (excluding any Software
                    and firmware forming part of, or supplied with, the Goods)
                    will pass to Customer upon Customer making payment in
                    full for those Goods, including any interest payable on
                    overdue amounts;
                  </li>
                  <li>
                    No Dealing or Modifying or Security Interests: Customer
                    must not sell, lend, lease, transfer, modify or otherwise
                    deal with the Goods without first obtaining our written
                    consent. Customer must not create, or allow to be
                    created, a Security Interest over the Goods until title has
                    passed to Customer;
                  </li>
                  <li>
                    Risk: Risk in any of the Goods will pass to Customer upon
                    Delivery of those Goods to Customer;
                  </li>
                  <li>
                    Licence to Software/Firmware: Customer will be licensed
                    to use any third party software and/or firmware forming
                    part of, or supplied with, the Goods on the relevant
                    manufacturer's or software developer's licence terms.
                    Customer must comply with all such licence terms;
                  </li>
                  <li>
                    Warranty: To the maximum extent permitted by law, and
                    subject to clause 11.1, we will repair or replace, at our
                    option, faulty Goods for a period of 1 year commencing
                    from the date the Goods are delivered to Customer, fair
                    wear and tear excepted and provided damage is not
                    caused by the Customer. i.e. liquid damage is not
                    covered by this warranty. Our Goods come with
                    guarantees that cannot be excluded under the Australian
                    Consumer Law. You are entitled to a replacement or
                    refund for a major failure and compensation for any other
                    reasonably foreseeable loss or damage. You are also
                    entitled to have the Goods repaired or replaced if the
                    Goods fail to be of acceptable quality and the failure
                    does not amount to a major failure;
                  </li>
                  <li>
                    Hold for Benefit of Customer: To the maximum extent
                    permitted by law, and subject to clause 11.1, where it is
                    unable to pass to Customer any manufacturer's
                    warranties in respect of any of the Goods supplied to
                    Customer under this Agreement, we will hold any such
                    warranties for the benefit of Customer; and
                  </li>
                  <li>
                    Recovery of Unpaid Retail Equipment: If Customer fails to
                    pay in full for any Goods which have been Delivered, we
                    may enter any premises where we reasonably believes
                    that the Goods may be located and Customer will provide
                    all reasonable authority and assistance to enable
                    recovery of those Goods. Customer will be liable for all
                    costs (including legal costs on a solicitor-client basis)
                    incurred in recovering the Goods
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Support Services</b>
            <ol>
              <li>
                Support: Flip will provide the Support Services to Customer.
              </li>
              <li>
                Emergency Support Services: Flip may make available the
                Emergency Support Services. If Customer requires services
                additional to the Support Services Flip Payments may, if it
                agrees to provide those services to Customer, charge the
                Customer for those services on a time and materials basis
                and on such other terms and conditions as may be agreed
                between the parties
              </li>
              <li>
                Alterations to Software: Flip may, at its sole discretion, alter,
                upgrade, update or change the Software at any time during
                the Term of this Agreement. Where we believe that such
                alteration, upgrade, update or change will, or is likely to,
                materially affect Customer's use of the Services, we will
                endeavour to notify the Customer of this in advance. The
                Customer acknowledges and agrees that, if the Customer
                fails to promptly install all updates to any software forming part
                of Services supplied by Flip in connection with this
                Agreement, the Customer may be unable to process
                Transactions and that:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Flip will not be liable to the Customer under this
                    Agreement in respect of such inability; and
                  </li>
                  <li>
                    Flip will be released from any obligation to supply the
                    Support Services during any period which the Customer
                    has failed to install any such update.
                  </li>
                </ol>
              </li>
              <li>
                Website: The Customer acknowledges and agrees that Flip
                may, at its sole discretion, determine what information, data,
                features and functionality is made available to the Customer
                via the Website. The Customer agrees to comply with any
                terms that Flip may specify in relation to the Customer's use
                of the Website. In the event of any inconsistency between the
                terms and conditions of this Agreement and those on the
                Website, the terms and conditions of this Agreement will
                apply to the extent of that inconsistency. Flip will supply user
                © Flip Money Pty Ltd | Page 6 of 6
                names and passwords to enable authorised users of The
                Customer to access the Website. The Customer must keep
                such user names and passwords secure and made known
                only to authorised users and will be responsible for all use of
                the Website through use of Customer's user names and
                passwords. If the Customer breaches any term of this
                Agreement or the terms of use of the Website, we may
                disable the Customer's user names and passwords
              </li>
            </ol>
          </li>
          <li>
            <b>General</b>
            <ol>
              <li>
                Entire Agreement: This Agreement records the entire
                arrangement between the parties relating to all matters dealt
                with in this Agreement and supersedes all previous
                arrangements, whether written, oral or both, relating to such
                matters
              </li>
              <li>
                Disputes: If the parties fail to negotiate a resolution to a
                dispute within a reasonable time (not exceeding 20 Business
                Days from formal notice of the dispute being given by one
                party to the other), either party may require that the dispute
                be submitted to mediation, such mediation to take place in
                Sydney, New South Wales, Australia. If the parties fail to agree
                on a mediator within 5 Business Days after the submission to
                mediation, one will be appointed by the President for the time
                being of the Law Society of New South Wales or its
                successor. In the event of any submission to mediation:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    the mediator will not be acting as an expert or as an
                    arbitrator;
                  </li>
                  <li>
                    the mediator will determine the procedure and timetable
                    for the mediation; and
                  </li>
                  <li>
                    the parties will share equally the cost of the mediation. All
                    discussions in mediation will be confidential and without
                    prejudice. Neither party may issue any legal proceedings
                    (other than for urgent interlocutory relief) relating to any
                    dispute, unless that party has first taken all reasonable
                    steps to comply with the dispute resolution process
                    above
                  </li>
                </ol>
              </li>
              <li>
                Waiver: No waiver of any breach, or failure to enforce any
                provision, of this Agreement at any time by either party shall
                in any way limit or waive the right of such party to
                subsequently enforce and compel strict compliance with the
                provisions of this Agreement.
              </li>
              <li>
                Severance: Any provisions in this Agreement which is or
                becomes unenforceable, illegal or invalid for any reason shall
                be severed or read down and shall not affect the
                enforceability, legality, validity or application of any other
                provision which shall remain in full force and effect.
              </li>
              <li>
                Assignment: Flip may assign all or any of its rights and
                obligations under this Agreement to any person without the
                Customer's consent. The Customer may not transfer or assign
                any of its liabilities or rights under this Agreement to any other
                person without Flip’s prior written consent (such consent not
                to be unreasonably withheld, provided Flip is satisfied as to
                the suitability of the assignee and the Customer meets Flip’s
                reasonable costs in relation to the assignment).
              </li>
              <li>
                Amendment: Except as expressly provided for in this
                Agreement, no amendment to this Agreement will be valid
                unless recorded in writing and signed by a duly authorised
                senior representative of each party.
              </li>
              <li>
                Governing Law and Jurisdiction: This Agreement is governed
                by the laws of New South Wales and the parties submit to the
                jurisdiction of the New South Wales courts in respect of all
                matters relating to this Agreement.
              </li>
              <li>
                Remedies: The rights, powers and remedies provided in this
                Agreement are not exclusive of any rights, powers or
                remedies provided by law.
              </li>
              <li>
                Subcontracting: Flip may appoint subcontractors to perform
                any of its obligations under this Agreement
              </li>
              <li>
                Counterparts and copies: This Agreement may be signed in
                any number of counterpart copies which, read together, will
                constitute one and the same document. Any facsimile copy
                of this agreement or copy of this agreement sent via email in
                PDF format (including any facsimile copy or copy sent via
                email in PDF format, of any document evidencing either
                party's signature of this agreement) may be relied upon by
                the other party as if it were an original copy. This Agreement
                may be entered into on the basis of an exchange of such
                facsimile or PDF copies
              </li>
              <li>
                Notices: Any notice or other communication to be given
                under this Agreement must be in writing and must be served
                by one of the following means and in respect of each is
                deemed to have been served as described:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    By personal delivery – when received by the party
                  </li>
                  <li>
                    By post by registered or ordinary mail – on the second
                    working day following the date of posting to the
                    addressee’s registered office
                  </li>
                  <li>
                    By email – when acknowledged by the party orally or by
                    return email or otherwise in writing
                  </li>
                  <li>
                    By facsimile – when sent to the correct facsimile number
                    (with written transmission confirmation)
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "PrivacyPolicy",

  data() {
    return {
      logo: "@/assets/images/logo/smallLogo.png",
      content: {}
    };
  },
  methods: {
    ...mapActions("page", ["fetchPageDetailsBySlug"])
  },
  mounted() {
    // this.fetchPageDetailsBySlug(this.$route.params.slug).then(res => {
    //   this.content = res.data;
    //
    // });
  }
};
</script>
<style>
ol {
  list-style-type: decimal;
}
ol li ol li {
  margin-left: 15px;
}
</style>
